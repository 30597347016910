import React from "react";
import {StaticQuery, graphql, Link} from "gatsby";
import Img from "gatsby-image";

import imgL1Logo from "../../assets/image/png/ce-tag-trans.png";
import imgL1LogoWhite from "../../assets/image/png/logo-white.png";

const Logo = ({white, height, className = "", ...rest}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: {eq: "image/png/ce-tag-trans.png"}) {
            childImageSharp {
              fixed(height: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => (
        <Link to="/" className={`${className}`} {...rest}>
          {white ? <Img fixed={data.file.childImageSharp.fixed} alt="" /> : <Img fixed={data.file.childImageSharp.fixed} alt="" />}
        </Link>
      )}
    />
  );
};

export default Logo;
